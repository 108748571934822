import { Box, Text } from '@chakra-ui/react';
import { CircularProgressComponent } from '@wow/progress-indicator';

import Panel from './styled';

function LoadingCircular() {
  return (
    <Panel>
      <Box className='container'>
        <CircularProgressComponent isIndeterminate value={75} />
        <Box className='text-container'>
          <h1>
            Estamos cargando la sección de <br /> Representantes legales / personas apoderadas
          </h1>
          <Text textStyle='bodyLg'>Esto puede demorar unos instantes.</Text>
        </Box>
      </Box>
    </Panel>
  );
}

export default LoadingCircular;
