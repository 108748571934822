import ModalInterface from '../../Interfaces/modalInterface';
import Modals from '../modal';

function ErrorAddRlFromRL({ isOpen, onClose, displayNone }: ModalInterface) {
  return (
    <Modals
      title={
        <>
          No podemos agregar <br />a tu representante
        </>
      }
      description={
        <>
          Estamos trabajando para solucionarlo. <br /> Por favor, volvé a intentarlo más tarde
        </>
      }
      buttonName2='Entendido'
      icon='cancel'
      isOpen={isOpen}
      onClose={onClose}
      buttonAction2={onClose}
      displayNone={displayNone}
    />
  );
}
export default ErrorAddRlFromRL;
