// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import Countries from '../Interfaces/countries';
import { CreateLegalRepresentativeRequestDto } from '../Interfaces/CreateLegalRepresentativeRequestDto';
import { DataSubscriptionService } from '../Interfaces/DataSubscriptionInterface';
import { EditLegalRepresentativeRequestDto } from '../Interfaces/EditLegalRepresentativeRequestDto';
import LegalRepresentative from '../Interfaces/legalRepresentative';

axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;

const newAxios = axios.create({
  baseURL: process.env.URL_REPRESENTANTE_LEGAL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: process.env.API_USER_KEY },
});
const newAxiosDNB = axios.create({
  baseURL: process.env.URL_BANCOS_DIGITALES,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: process.env.API_USER_KEY_DNB },
});

// export const getRepresentanteLegal = (operador: number) => newAxios.get<{ data: RepresentanteLegal[] }>(`representante-legal/${operador}`);
export const getLegalRepresentative = () => newAxios.get<{ data: { legalRepresentatives: LegalRepresentative[] } }>(`/representante-legal`);
export const putEditEmail = (idPersona: number, email: string) => newAxios.put(`/editar-mail/${idPersona}`, { email });
export const deleteLegalRepresentative = (legalRepresentativeId: number) =>
  newAxios.patch<{ data: { LegalRepresentative: LegalRepresentative[] } }>(`/v1/legal-representative/${legalRepresentativeId}/disable`);
export const editEmail = ({ legalRepresentativeId, newEmailDirection, sendValidationEmailNow }: EditLegalRepresentativeRequestDto) =>
  newAxios.patch(`/representante-legal/${legalRepresentativeId}/email`, {
    newEmailDirection,
    sendValidationEmailNow,
  });
export const sendMail = (operador: number, personaId: number, lastName: string, name: string) =>
  newAxios.post(`/reenviar-email/${operador}`, { personaId, lastName, name });
export const getPaises = () => newAxios.get<{ data: Countries }>(`/paises`);
export const getApplicationsConfirm = (cuit: string) =>
  newAxiosDNB.get<DataSubscriptionService>(`/suscripciones/solicitudes/confirmar?cuit=${cuit}`);
export const createLegalRepresentative = ({
  name,
  lastName,
  emailDirection,
  countryCode,
  documentNumber,
  documentType,
  sendValidationEmailNow,
}: CreateLegalRepresentativeRequestDto) =>
  newAxios.post(`/v1/legal-representatives/create-legal-representative`, {
    name,
    lastName,
    emailDirection,
    countryCode,
    documentNumber,
    documentType,
    sendValidationEmailNow,
  });
