/* eslint-disable no-nested-ternary */
import { Box, Button, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { UserPlus } from 'react-feather';
import { useMutation } from 'react-query';

import useCountries from '../../hooks/useCountries';
import { CreateLegalRepresentativeRequestDto } from '../../Interfaces/CreateLegalRepresentativeRequestDto';
import LegalRepresentative from '../../Interfaces/legalRepresentative';
import { createLegalRepresentative } from '../../services/api';
import DrawerGen from '../drawerGen';
import FormAddRl from '../formAddRl';
import ErrorAddRlFromRL from '../formAddRl/ErrorAddRlModal';
import HeaderPanel from './styles';
import SuccessAddRlFromDNB from './SuccessAddRlFromDNB';
import SuccessAddRlFromRL from './SuccessAddRlFromRL';

function Header({ application, data, fetchData }: { application: string; data?: LegalRepresentative[]; fetchData?: () => void }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ModalQuerySuccess = useDisclosure();
  const ModalQueryFailed = useDisclosure();
  const btnRef = React.useRef();
  const title = 'Agregar representante legal o persona apoderada';
  const description =
    'Completá los siguientes datos teniendo en cuenta que deben ser las mismas personas que figuran como representantes legales o apoderadas en el banco.';

  const saveRl = useMutation(
    (rl: CreateLegalRepresentativeRequestDto) =>
      createLegalRepresentative({
        name: rl.name,
        lastName: rl.lastName,
        emailDirection: rl.emailDirection,
        countryCode: rl.countryCode,
        documentNumber: rl.documentNumber,
        documentType: rl.documentType,
        sendValidationEmailNow: rl.sendValidationEmailNow,
      }),
    {
      onSuccess: () => {
        ModalQuerySuccess.onOpen();
        fetchData();
      },
      onError: () => {
        ModalQueryFailed.onOpen();
      },
    },
  );

  const { data: countriesData } = useCountries();

  const canAddMoreRls = data?.length < 3 && data.length !== 0;
  const emptyRl = !data || data.length === 0;

  const dynamicButtonVariant = () => {
    if (application === 'digitales') {
      return 'primary-outline';
    }
    return 'primary-outline';
  };

  const addRl = () => {
    onOpen();
  };

  return (
    <HeaderPanel>
      <Box className='rlFlexiStart'>
        <Box className='rlTitleTooltip'>
          <Text as='h2' textStyle='h2Bold'>
            Representantes legales / personas apoderadas
          </Text>
          <Tooltip placement='right' hasArrow label='Agregá a las personas que tienen facultades de contratar servicios en la empresa.'>
            <Box className='helpIconRl' />
          </Tooltip>
        </Box>

        {emptyRl ? null : canAddMoreRls ? (
          <Box>
            <Button id='Agregar' onClick={addRl} leftIcon={<UserPlus />} size='md' variant={dynamicButtonVariant()}>
              Agregar
            </Button>
          </Box>
        ) : (
          <Tooltip placement='right' hasArrow label='Llegaste al máximo de representantes legales y/o personas apoderadas permitidas'>
            <Box>
              <Button id='Agregar' isDisabled onClick={addRl} leftIcon={<UserPlus />} size='sm' variant={dynamicButtonVariant()}>
                Agregar
              </Button>
            </Box>
          </Tooltip>
        )}
      </Box>
      <Text mt='10px' as='p' textStyle='bodyMd'>
        Las personas con este rol confirmarán las solicitudes de alta para operar en Interbanking. Podés agregar hasta 3.
      </Text>
      {ModalQuerySuccess.isOpen && application === 'digitales' ? (
        <SuccessAddRlFromDNB
          isOpen={ModalQuerySuccess.isOpen}
          onClose={ModalQuerySuccess.onClose}
          buttonAction1={() => addRl()}
          displayNone={canAddMoreRls}
        />
      ) : (
        <SuccessAddRlFromRL
          isOpen={ModalQuerySuccess.isOpen}
          onClose={ModalQuerySuccess.onClose}
          buttonAction1={() => addRl()}
          displayNone={canAddMoreRls}
        />
      )}
      <ErrorAddRlFromRL
        isOpen={ModalQueryFailed.isOpen}
        onClose={ModalQueryFailed.onClose}
        buttonAction1={() => addRl()}
        displayNone={canAddMoreRls}
      />

      <DrawerGen title={title} description={description} isOpen={isOpen} onClose={onClose} btnRef={btnRef}>
        <FormAddRl
          button1={{ title: 'Cancelar', action: onClose }}
          button2={{ title: 'Agregar', action: rl => saveRl.mutate(rl as CreateLegalRepresentativeRequestDto) }}
          countries={countriesData}
          rls={data}
        />
      </DrawerGen>
    </HeaderPanel>
  );
}
export default Header;
