import ModalInterface from '../../Interfaces/modalInterface';
import Modals from '../modal';

function SuccessEditRlModal({ isOpen, onClose, buttonAction1 }: ModalInterface) {
  return (
    <Modals
      title={
        <>
          Actualizamos los datos <br />
          con éxito
        </>
      }
      description={<>Tu representante legal / persona apoderada deberá confirmar la solicitud de alta cuando termines de crearla.</>}
      buttonName1='Entendido'
      buttonAction1={() => buttonAction1()}
      icon='confirm'
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
export default SuccessEditRlModal;
