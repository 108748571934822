/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Select } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';

import { CreateLegalRepresentativeRequestDto } from '../../Interfaces/CreateLegalRepresentativeRequestDto';
import FormAddRlInterface from '../../Interfaces/formAddRl';
import rlData from '../../Interfaces/saveLegalRepresentativel';
import formatCuil from '../../utils/cuilUtils';
import preventNoDniKeyPressHandler from '../../utils/preventNoDniKeyPressHandler';
import preventNoEmailKeyPressHandler from '../../utils/preventNoEmailKeyPressHandler';
import Arrow from '../btns/arrow';
import FormPanel from './styles';

function FormAddRl({ button1, button2, countries, rls }: FormAddRlInterface) {
  const initialValues = { name: '', lastName: '', email: '', country: 'Argentina', cuil: '', type: 'Documento', dniOrPassport: '' };
  const type = ['Documento', 'Pasaporte'];
  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Ingresá más caracteres')
      .max(30, 'Superaste el máximo de caracteres permitidos')
      .required('Campo requerido')
      .test('no-dos-espacios-consecutivos', 'Revisa el dato ingresado', value => !/\s{2}/.test(value)),
    lastName: Yup.string()
      .min(2, 'Ingresá más caracteres')
      .max(25, 'Superaste el máximo de caracteres permitidos')
      .required('Campo requerido')
      .test('no-dos-espacios-consecutivos', 'Revisa el dato ingresado', value => !/\s{2}/.test(value)),
    email: Yup.string()
      .email('El formato ingresado es incorrecto')
      .max(40, 'Superaste el máximo de caracteres permitidos')
      .required('Campo requerido')
      .test('email-different', 'Ya tenés una persona representante con ese email', value => {
        const isEmailInUse = rls?.some(selectedRl => selectedRl.emailDirection === value);
        return !isEmailInUse;
      })
      .matches(/^[A-Za-z0-9\-_.]+@[A-Za-z0-9\-_.]+\.[A-Za-z]{2,3}$/, {
        message: 'El formato ingresado es incorrecto',
        excludeEmptyString: true,
      }),
    country: Yup.string().required('Campo requerido'),
    cuil: Yup.string().when('country', (country, schema) => {
      if (country[0] === 'Argentina') {
        return schema
          .required('Campo requerido')
          .matches(/^[0-9]{2}-[0-9]{8}-[0-9]{1}$/, 'Revisá que el cuil sea correcto')
          .required('Campo requerido')
          .min(13, 'Revisá que el cuil sea correcto')
          .max(13, 'Revisá que el cuil sea correcto')
          .test('cuit-prefix', 'Revisá que el cuil sea correcto', value => {
            const prefixes = ['20', '23', '24', '27'];
            return prefixes.some(prefix => value.startsWith(prefix));
          })
          .test('cuit-different', 'Ya tenés una persona representante con ese Cuil', function (value) {
            const formattedValue = value.replace(/-/g, '');
            return !rls?.some(selectedRl => selectedRl.documentNumber === formattedValue);
          });
      }
      return schema;
    }),

    type: Yup.string().when('country', country => {
      if (country[0] !== 'Argentina') return Yup.string().required('Campo requerido');
    }),
    dniOrPassport: Yup.string().when('country', (country, schema) => {
      if (country[0] !== 'Argentina') {
        return schema
          .required('Campo requerido')
          .matches(/^[A-Za-z0-9]+$/, 'Revisa el dato ingresado')
          .min(1, 'Ingresá más caracteres')
          .max(15, 'Superaste el máximo de caracteres permitidos');
      }
      return schema;
    }),
  });

  const countryOptions = useMemo(() => {
    return countries.map(pais => (
      <option key={pais.nombre.trim()} value={pais.nombre.trim()}>
        {pais.nombre.trim()}
      </option>
    ));
  }, [countries]);

  return (
    <FormPanel>
      <div className='formikRl'>
        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={(values: rlData) => {
            const selectedCountry = countries.find(country => country.nombre.trim() === values.country);
            const countryCode = selectedCountry ? selectedCountry.codDN : 'AR';

            if (values.country === 'Argentina') {
              const rl: CreateLegalRepresentativeRequestDto = {
                name: values.name.trim(),
                lastName: values.lastName.trim(),
                emailDirection: values.email.trim(),
                countryCode,
                documentNumber: values.cuil.replace(/-/g, ''),
                documentType: 'CUIL',
                sendValidationEmailNow: false,
              };

              button2.action(rl);
            } else {
              const rl: CreateLegalRepresentativeRequestDto = {
                name: values.name.trim(),
                lastName: values.lastName.trim(),
                emailDirection: values.email.trim(),
                countryCode,
                documentNumber: values.dniOrPassport.trim().toUpperCase(),
                documentType: values.type === 'Documento' ? 'DOCUMENT' : 'PASSPORT',
                sendValidationEmailNow: false,
              };

              button2.action(rl);
            }
            button1.action(values);
          }}
        >
          {({ errors, touched, values, isValid, isSubmitting, setFieldValue, dirty }) => (
            <Form autoComplete='nope'>
              <Box className='boxForm'>
                <Box className='boxError'>
                  <Field name='name'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel className={`labelRl ${form.errors.name && form.touched.name ? 'errorLabel' : ''}`} textStyle='labelMd'>
                          Nombre
                        </FormLabel>
                        <Input autocomplete='nope' {...field} id='name' placeholder='Ingresá el nombre' aria-label='Nombre' />
                        <FormErrorMessage> {form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box className='boxError'>
                  <Field name='lastName'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
                        <FormLabel
                          className={`labelRl ${form.errors.lastName && form.touched.lastName ? 'errorLabel' : ''}`}
                          htmlFor='lastName'
                          textStyle='labelMd'
                        >
                          Apellido
                        </FormLabel>
                        <Input autocomplete='nope' {...field} id='lastName' placeholder='Ingresá el apellido' aria-label='Apellido' />
                        {errors.lastName && touched.lastName ? <FormErrorMessage>{errors.lastName}</FormErrorMessage> : null}
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </Box>
              <Box className='boxForm'>
                <Box className='boxError'>
                  <Field name='email'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel className={`labelRl ${form.errors.email && form.touched.email ? 'errorLabel' : ''}`} htmlFor='email'>
                          Email
                        </FormLabel>
                        <InputGroup>
                          <Input
                            aria-label='Email'
                            autocomplete='nope'
                            {...field}
                            onKeyPress={preventNoEmailKeyPressHandler}
                            id='email'
                            name='email'
                            placeholder='Ingresá el email'
                            type='email'
                          />{' '}
                        </InputGroup>
                        {errors.email && touched.email ? <FormErrorMessage>{errors.email}</FormErrorMessage> : null}
                      </FormControl>
                    )}
                  </Field>
                </Box>

                <Box className='boxError'>
                  <Field name='country'>
                    {({ field }) => (
                      <FormControl>
                        <FormLabel className='labelRl' htmlFor='country' textStyle='labelMd'>
                          País emisor del documento
                        </FormLabel>

                        <Select {...field} icon={<Arrow />} width={227} className='rlSelect' id='country'>
                          {countryOptions}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </Box>

              {values.country === 'Argentina' ? (
                <Box className='boxForm'>
                  <Box className='boxCuit boxError'>
                    <Field name='cuil'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.cuil && form.touched.cuil}>
                          <FormLabel
                            className={`labelRl ${form.errors.cuil && form.touched.cuil ? 'errorLabel' : ''}`}
                            htmlFor='cuil'
                            textStyle='labelMd'
                          >
                            CUIL
                          </FormLabel>
                          <Input
                            {...field}
                            id='cuil'
                            autocomplete='nope'
                            placeholder='Ingresá el número de CUIL'
                            maxLength={13}
                            onChange={e => {
                              const formattedCuil = formatCuil(e.currentTarget.value);
                              setFieldValue('cuil', formattedCuil);
                            }}
                          />
                          {errors.cuil && touched.cuil ? <FormErrorMessage>{errors.cuil}</FormErrorMessage> : null}
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                </Box>
              ) : (
                <Box className='boxForm'>
                  <Box className='boxError'>
                    <Field name='type'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.type && form.touched.type}>
                          <FormLabel
                            className={`labelRl ${form.errors.type && form.touched.type ? 'errorLabel' : ''}`}
                            htmlFor='type'
                            textStyle='labelMd'
                          >
                            Tipo de documento
                          </FormLabel>
                          <Select {...field} icon={<Arrow />} width={227} className='rlSelect' id='type'>
                            {type.map(e => (
                              <option key={e} value={e}>
                                {e}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box className='boxError'>
                    <Field name='dniOrPassport'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.dniOrPassport && form.touched.dniOrPassport}>
                          <FormLabel
                            className={`labelRl ${form.errors.dniOrPassport && form.touched.dniOrPassport ? 'errorLabel' : ''}`}
                            htmlFor='dniOrPassport'
                            textStyle='labelMd'
                          >
                            {values.type}
                          </FormLabel>
                          <Input
                            {...field}
                            onKeyPress={preventNoDniKeyPressHandler}
                            id='dniOrPassport'
                            placeholder={`Ingresá el ${values.type}`}
                          />
                          {errors.dniOrPassport && touched.dniOrPassport ? (
                            <FormErrorMessage>{errors.dniOrPassport}</FormErrorMessage>
                          ) : null}
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                </Box>
              )}
              <Box className='bar' />

              <div className='btnsRl'>
                <Button id='cancelar' variant='primary-outline' onClick={button1.action}>
                  {button1.title}
                </Button>

                <Button isDisabled={!isValid || isSubmitting || !dirty} id='agregar' variant='primary' type='submit'>
                  {button2.title}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormPanel>
  );
}
export default FormAddRl;
