/* eslint-disable react/jsx-props-no-spreading */

import { Avatar, Box, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { ToastComponent } from '@wow/toast';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { ValidationState } from '../../enum/validation-state.enum';
import { EditLegalRepresentativeRequestDto } from '../../Interfaces/EditLegalRepresentativeRequestDto';
import LegalRepresentative from '../../Interfaces/legalRepresentative';
import { deleteLegalRepresentative, editEmail, sendMail } from '../../services/api';
import DrawerGen from '../drawerGen';
import FormEditRl from '../formEditRl';
import Modals from '../modal';
import ErrorEditRlModal from './ErrorEditRlModal';
import RlFullInterface from './rlFullInterface';
import RlFullPanel from './styles';
import SuccessEditRlModal from './SuccessEditRlModal';

function RlFull({ data, operador, fetchData, microfrontend }: Readonly<RlFullInterface>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const ModalQuerySuccess = useDisclosure();
  const ModalQueryFailed = useDisclosure();
  const ModalSendMailFailed = useDisclosure();
  const ModalEditMailSuccess = useDisclosure();
  const ModalEditMailFailed = useDisclosure();
  const isApplicationDigital = microfrontend === 'digitales';
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [selectedRl, setSelectedRl] = useState({
    id: 0,
    name: '',
    lastname: '',
    emailDirection: '',
    country: '',
    documentType: '',
    legalRepresentativeStatus: '',
    documentNumber: '',
    emailStatus: '',

    /*
  
  documentType: string;
  emailDirection: string;
  
    */
  });

  const btnRef = React.useRef();
  const title = 'Datos de representante legal o persona apoderada';
  const toast = useToast();
  const openToast = (status, description) => {
    toast({
      containerStyle: {
        marginLeft: '80px',
      },
      id: 'toast-id',
      duration: 4000, // min 4000 ms | max 10000 ms
      position: 'bottom-left', // 'bottom' | 'bottom-left'
      status, // 'info' | 'success' | 'error'
      description,
      render: props => <ToastComponent {...props} />,
    });
  };

  const editRl = useMutation(
    async (rl: EditLegalRepresentativeRequestDto) => {
      setIsEditingEmail(true);
      await editEmail({
        legalRepresentativeId: rl.legalRepresentativeId,
        newEmailDirection: rl.newEmailDirection,
        sendValidationEmailNow: false,
      });
    },

    {
      onSuccess: () => {
        ModalEditMailSuccess.onOpen();
        fetchData();
      },

      onError: () => {
        ModalEditMailFailed.onOpen();
      },
      onSettled: () => {
        setIsEditingEmail(false);
      },
    },
  );

  const sendMailMutation = useMutation(
    async (rl: LegalRepresentative) => {
      setIsSendingMail(true);
      await sendMail(operador, rl.id, rl.lastname, rl.name);
    },
    {
      onSuccess: () => {
        openToast('success', 'Enviamos con éxito la notificación por email');
      },
      onError: (error: Error) => {
        openToast('error', 'No pudimos hacer la notificación. Inténtalo nuevamente más tarde');
        console.error(error);
      },
      onSettled: () => {
        setIsSendingMail(false);
      },
    },
  );

  const resendMail = async rl => {
    try {
      await sendMailMutation.mutateAsync(rl);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteMutation = useMutation(
    async (rl: LegalRepresentative) => {
      await deleteLegalRepresentative(rl.id);
    },
    {
      onSuccess: () => {
        fetchData();
        openToast('success', 'Eliminaste a tu representante legal / persona apoderada con éxito.');
      },
      onError: (error: Error) => {
        openToast('error', 'No podemos eliminar a tu representante, intentá en otro momento.');
        console.error(error);
      },
      onSettled: () => {
        setIsDeleteModalOpen(false);
      },
    },
  );

  const handleDelete = async () => {
    if (selectedRl) {
      try {
        await handleDeleteMutation.mutateAsync(selectedRl);
        closeModalDelete();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const openModalDelete = rl => {
    try {
      setSelectedRl(rl);
      setIsDeleteModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeModalDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedRl(null);
  };

  const openDrawerForEdit = rl => {
    onOpen();
    setSelectedRl(rl);
  };

  return (
    <RlFullPanel>
      <Box mt='20px' className='my-tab-Rl'>
        <Box className='table-Rl'>
          <Text textStyle='titleSmBold' className='header-table-rl'>
            Nombre y apellido
          </Text>
          <Text textStyle='titleSmBold' className='header-table-rl'>
            País emisor del Documento
          </Text>
          <Text textStyle='titleSmBold' className='header-table-rl widthMin'>
            CUIL/Documento
          </Text>
          <Text textStyle='titleSmBold' className='header-table-rl' mr='20px'>
            Email
          </Text>
          {/* se deja comentado ya que en un futuro se vuelva a utilizar ESTADO */}
          {/* <Text textStyle='titleSmBold' className='header-table-rl'>
            Estado
          </Text> */}
          <Box className='header-table-rl' />
        </Box>

        {data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(representanteLegal => {
            const sendEmailButtonDisabled =
              representanteLegal.emailStatus === ValidationState.VALIDADO || isApplicationDigital || isSendingMail;
            return (
              <Box key={representanteLegal.id} className='table-in-Rl'>
                <Box className='colum-table-rl'>
                  <Avatar
                    backgroundColor='#2172DC'
                    size='sm'
                    color='white'
                    name={`${representanteLegal.name} ${representanteLegal.lastname}`}
                  />
                  <Box ml='12px' className='uppercase '>
                    <Text ml='5px' textStyle='labelSmBold'>{`${representanteLegal.name} ${representanteLegal.lastname}`}</Text>
                  </Box>
                </Box>
                <Box className='colum-table-rl uppercase'>
                  <Text ml='4px' textStyle='bodySm'>
                    {representanteLegal.country}
                  </Text>
                </Box>
                {representanteLegal.documentNumber.length === 11 ? (
                  <Box className='colum-table-rl widthMin'>
                    <Text ml='2px' textStyle='bodySm'>{`${representanteLegal.documentNumber.slice(
                      0,
                      2,
                    )}-${representanteLegal.documentNumber.slice(2, 10)}-${representanteLegal.documentNumber.slice(10, 12)}`}</Text>
                  </Box>
                ) : (
                  <Box className='colum-table-rl widthMin'>
                    <Text ml='2px' textStyle='bodySm'>
                      {representanteLegal.documentNumber}
                    </Text>
                  </Box>
                )}
                <Box className='colum-table-rl mar-Rl'>
                  <Tooltip hasArrow label={representanteLegal.emailDirection}>
                    <Text ml='2px' textStyle='bodySm'>
                      {representanteLegal.emailDirection}
                    </Text>
                  </Tooltip>
                </Box>
                {/* se deja comentado ya que en un futuro se vuelva a utilizar ESTADO */}
                {/* <Box className='colum-table-rl'>
                <Badge variant={`${representanteLegal.emailStatus === ValidationState.VALIDADO ? 'success-light' : 'warning-dark'}`}>
                  {`${representanteLegal.emailStatus === ValidationState.VALIDADO ? 'Email validado' : 'Email por validar'}`}
                </Badge>
              </Box> */}
                <Box className='colum-table-rl-icons'>
                  <Tooltip placement='left' hasArrow label='Editar'>
                    <Box mt='5px'>
                      <button
                        disabled={isEditingEmail}
                        onClick={() => openDrawerForEdit(representanteLegal)}
                        data-testid='Editar'
                        className={`${isEditingEmail ? 'edit-rl-dis' : 'edit-rl'}`}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    label={`${representanteLegal.emailStatus !== ValidationState.VALIDADO ? 'Notificalo para validar su email' : ''}`}
                    placement='left'
                  >
                    <Box className='marginButton'>
                      <button
                        data-testid='Enviar'
                        disabled={sendEmailButtonDisabled}
                        onClick={() => resendMail(representanteLegal)}
                        className={`${sendEmailButtonDisabled ? 'send-rl-dis' : 'send-rl'}`}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip hasArrow label='Eliminar' placement='left'>
                    <Box className='marginButton'>
                      <button
                        data-testid={`Eliminar-${representanteLegal.id}`}
                        id='Eliminar'
                        onClick={() => openModalDelete(representanteLegal)}
                        className={`${'trash-rl'}`}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}

        {ModalQuerySuccess.isOpen ? (
          <Modals
            title='Enviamos un email de confirmación'
            description='Para finalizar la actualización, el representante legal o persona apoderada debe validarlo.'
            buttonName1='Entendido'
            icon='confirm'
            isOpen={ModalQuerySuccess.isOpen}
            onClose={ModalQuerySuccess.onClose}
            buttonAction1={ModalQuerySuccess.onClose}
          />
        ) : null}
        {ModalQueryFailed.isOpen ? (
          <Modals
            title={
              <>
                No podemos <br />
                editar el email
              </>
            }
            description={
              <>
                Estamos trabajando para solucionarlo. <br />
                Volvé a intentar más tarde.
              </>
            }
            buttonName1='Entendido'
            icon='cancel'
            isOpen={ModalQueryFailed.isOpen}
            onClose={ModalQueryFailed.onClose}
            buttonAction1={ModalQueryFailed.onClose}
          />
        ) : null}
        {ModalSendMailFailed.isOpen ? (
          <Modals
            title={
              <>
                No podemos enviar <br />
                la notificación
              </>
            }
            description={<>Revisá el mail y volvé a intentar más tarde.</>}
            buttonName1='Entendido'
            icon='alert'
            isOpen={ModalSendMailFailed.isOpen}
            onClose={ModalSendMailFailed.onClose}
            buttonAction1={ModalSendMailFailed.onClose}
          />
        ) : null}
        <SuccessEditRlModal
          isOpen={ModalEditMailSuccess.isOpen}
          onClose={ModalEditMailSuccess.onClose}
          buttonAction1={ModalEditMailSuccess.onClose}
        />
        <ErrorEditRlModal
          isOpen={ModalEditMailFailed.isOpen}
          onClose={ModalEditMailFailed.onClose}
          buttonAction1={ModalEditMailFailed.onClose}
        />

        {isDeleteModalOpen && (
          <Modals
            title='¿Querés eliminar a tu representante?'
            description={
              <>
                Al hacerlo, {selectedRl?.name} {selectedRl?.lastname} dejará de ser representante legal / persona apoderada.
              </>
            }
            buttonName1='Cancelar'
            buttonName2='Si, eliminar'
            icon='alert'
            isOpen={isDeleteModalOpen}
            onClose={closeModalDelete}
            buttonAction1={closeModalDelete}
            buttonAction2={handleDelete}
          />
        )}
        <DrawerGen title={title} isOpen={isOpen} onClose={onClose} btnRef={btnRef}>
          <FormEditRl
            button1={{ title: 'Cancelar', action: onClose }}
            button2={{ title: 'Guardar', action: rl => editRl.mutate(rl as EditLegalRepresentativeRequestDto) }}
            selectedRl={selectedRl}
            microfrontend={microfrontend}
            rls={data}
          />
        </DrawerGen>
      </Box>
    </RlFullPanel>
  );
}

export default RlFull;
