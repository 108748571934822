// import './index.css';
import { Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react';

import alert from '../../assets/img/beware.svg';
import cancel from '../../assets/img/cancel.svg';
import confirm from '../../assets/img/checkmark.svg';
import ModalInterface from '../../Interfaces/modalInterface';
import ModalPanel from './styles';

function Modals({
  title,
  description,
  buttonName1,
  buttonAction2,
  buttonAction1,
  buttonDisabled1,
  buttonName2,
  buttonDisabled2,
  icon,
  isOpen,
  onClose,
  displayNone,
}: ModalInterface) {
  const iconImage = {
    confirm,
    cancel,
    alert,
  };
  const iconDefault = confirm;
  const altText = icon && typeof icon === 'string' ? icon : 'success';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalPanel>
        <ModalOverlay bg='rgba(0, 0, 0, 0.75)' />
        <ModalContent>
          <div className='image-style'>
            {/* Muestra la imagen correspondiente al type */}
            <img className='image-size' src={icon ? iconImage[icon] : iconDefault} alt={altText} />
          </div>
          <Text className='aling-center' textStyle='h3'>
            {title}
          </Text>
          <ModalCloseButton />
          <Text className='text-style' textStyle='bodyMd'>
            {description}
          </Text>
          <ModalFooter className='button-container'>
            {buttonName2 ? (
              <>
                <Button
                  isDisabled={buttonDisabled1}
                  className={`button-white-Style ${displayNone ? 'display-none' : null}`}
                  colorScheme='blue'
                  mr={3}
                  onClick={() => {
                    onClose();
                    buttonAction1();
                  }}
                  _hover={{ background: '#2172DC' }}
                >
                  {buttonName1}
                </Button>

                <Button
                  isDisabled={buttonDisabled2}
                  className='button-blue-Style'
                  colorScheme='blue'
                  mr={3}
                  onClick={() => {
                    onClose();
                    buttonAction2();
                  }}
                  _hover={{ background: '#2172DC' }}
                >
                  {buttonName2}
                </Button>
              </>
            ) : (
              <Button
                isDisabled={buttonDisabled1}
                className='button-blue-Style'
                colorScheme='blue'
                mr={3}
                onClick={() => {
                  onClose();
                  buttonAction1();
                }}
                _hover={{ background: '#2172DC' }}
              >
                {buttonName1}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalPanel>
    </Modal>
  );
}

export default Modals;

/*
Este modal es customizable: Recibe 4 props
textTitle: Nombre del titulo requerido
textConfirm: Nombre del texto requerido
buttonName: Nombre del boton requerido
type: Acepta 3 tipos  'confirm' / 'alert' / 'cancel' depende de cual seleccione mostrara una imagen u otra.

Ejemplo sencillo de como utilizarlo

import Modals from '../modal';

 <Modals
  textTitle="Listo"
  textConfirm="Tené en cuenta que la persona que agregaste debe validar su email para confirmar el alta como representante legal o persona apoderada."
  buttonName="Entendido"
  type="confirm"
/>


*/
