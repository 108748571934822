import ModalInterface from '../../Interfaces/modalInterface';
import Modals from '../modal';

function ErrorEditRlModal({ isOpen, onClose }: ModalInterface) {
  return (
    <Modals
      title={
        <>
          No podemos <br /> actualizar los datos
        </>
      }
      description={
        <>
          Estamos trabajando para solucionarlo. <br /> Volvé a intentar más tarde.
        </>
      }
      buttonName1='Entendido'
      icon='cancel'
      isOpen={isOpen}
      onClose={onClose}
      buttonAction1={onClose}
    />
  );
}
export default ErrorEditRlModal;
