/* eslint-disable react/jsx-props-no-spreading */
import { Avatar, Box, Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Text } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import FormEditRlInterface from '../../Interfaces/formEditRl';
import FormPanel from './styles';

function FormEditRl({ button1, button2, selectedRl, rls }: Readonly<FormEditRlInterface>) {
  interface Email {
    email: string;
  }
  const initialValues = { email: selectedRl.emailDirection };
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .email('El formato ingresado es incorrecto')
      .max(40, 'Superaste el máximo de caracteres permitidos')
      .required('Campo requerido')
      .matches(/^[A-Za-z0-9\-_.]+@[A-Za-z0-9\-_.]+\.[A-Za-z]{2,3}$/, {
        message: 'El formato ingresado es incorrecto',
        excludeEmptyString: true,
      })
      .test('email-different', 'Este email ya está en uso, intentá con otro', value => {
        return rls.every(rl => rl.emailDirection !== value);
      }),
  });
  const emailKeyPress = event => {
    const pattern = /^[A-Za-z0-9\-_.@]+$/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  };
  const rlCuil =
    selectedRl.documentNumber.length === 11
      ? `${selectedRl.documentNumber.slice(0, 2)}-${selectedRl.documentNumber.slice(2, 10)}-${selectedRl.documentNumber.slice(10, 12)}`
      : selectedRl.documentNumber;
  return (
    <FormPanel>
      {/* se deja comentado por si en un futuro se usa el modulo de mrl generico */}
      {/* {microfrontend ? null : (
        <Box className='descriptionBox' mt='10px'>
          <Box p='10px' className='boxIcon'>
            <Icon className='alertIcon' boxSize={6} as={AlertTriangle} />
          </Box>
          <Box className='boxValidar' pr='15px' pl='15px'>
            <Text mt='0px' mb='0px' textStyle='titleSmBold'>
              Validar email
            </Text>
            <Text m='0px' textStyle='bodyMd'>
              Tené en cuenta que al editar el email deberán validarlo de nuevo.
            </Text>
          </Box>
        </Box>
      )} */}
      <Box className='rlDataBox'>
        <Box className='avatarBox'>
          <Avatar m='15px' backgroundColor='#2172DC' size='lg' color='white' name={`${selectedRl.name} ${selectedRl.lastname}`} />
        </Box>
        <Box>
          <Text className='uppercase' mt='12px' mb='5px' textStyle='titleMdBold'>
            {`${selectedRl.name} ${selectedRl.lastname}`}
          </Text>
          <Box className='boxFlex'>
            <Text m='0px' textStyle='bodyMdSemi'>
              País emisor del documento:&nbsp;
            </Text>
            <Text m='0px' textStyle='bodyMd'>
              {selectedRl.country}
            </Text>
          </Box>
          <Box mb='10px' className='boxFlex'>
            <Text m='0px' textStyle='bodyMdSemi'>
              CUIL/Documento:&nbsp;
            </Text>
            <Text m='0px' textStyle='bodyMd'>
              {rlCuil}
            </Text>
          </Box>
          {/* se deja comentado por si en un futuro se usa el modulo de mrl generico */}
          {/* <Badge mt='5px' mb='15px' variant={`${selectedRl.emailStatus === 'VALIDADO' ? 'success-light' : 'warning-light'}`}>
            {`${selectedRl.emailStatus === 'VALIDADO' ? 'Email validado' : 'Email por validar'}`}
          </Badge> */}
        </Box>
      </Box>
      <Box className='formikRl'>
        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={async (values: Email) => {
            try {
              await button2.action({
                legalRepresentativeId: selectedRl.id,
                newEmailDirection: values.email,
                sendValidationEmailNow: false,
              });
              await button1.action(selectedRl);
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({ errors, isValid, isSubmitting }) => (
            <Form>
              <Box className='boxForm'>
                <Box className='boxError'>
                  <Field name='email'>
                    {({ field }) => (
                      <FormControl isInvalid={!!errors.email}>
                        <FormLabel className='labelRl' htmlFor='email'>
                          Email
                        </FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            onKeyPress={emailKeyPress}
                            id='email'
                            name='email'
                            placeholder='Ingresá el email'
                            type='email'
                            w='457px'
                          />
                        </InputGroup>
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </Box>
              <Box className='bar' />
              <Box className='btnsRl'>
                <Button id='cancelar' variant='primary-outline' onClick={button1.action}>
                  <Text textStyle='labelLgBold'>{button1.title}</Text>
                </Button>

                <Button isDisabled={!isValid || isSubmitting} id='actualizar' variant='primary' type='submit'>
                  <Text textStyle='labelLgBold'>{button2.title}</Text>
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </FormPanel>
  );
}
export default FormEditRl;
