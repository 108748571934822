// eslint-disable-next-line import/no-unresolved
import { ErrorContentComponent } from '@wow/error-content';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import RequireAccount from './components/RequireAccount';
import Project from './layouts/project';
import WithOperatorsPermissions from './utils/WithOperatorPermissions';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path='*'
          element={
            <ErrorContentComponent
              ErrorContentProps={{
                accessError: 'content',
                bodyText: 'Puede que el enlace haya cambiado o no exista más. Revisá la URL y volvé a intentar más tarde.',
                linkText: 'Ir al Inicio',
                redirectTo: '/',
                isRouter: true,
              }}
            />
          }
        />
        <Route
          path='/modulo-representante-legal/digitales/bancos/resumen-suscripcion'
          element={
            <WithOperatorsPermissions>
              <RequireAccount pathBase='/digitales'>
                <Project />
              </RequireAccount>
            </WithOperatorsPermissions>
          }
        />
        {/* Se dejan comentadas estas rutas ya que era una forma "genérica" de manejo de las mismas que por el momento no se está usando */}
        {/* <Route path='/modulo-representante-legal/:back/:continue' element={<Project />} /> */}
        {/* <Route path='/modulo-representante-legal' element={<Project />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
