import ModalInterface from '../../Interfaces/modalInterface';
import Modals from '../modal';

function SuccessAddRlFromDNB({ isOpen, onClose, buttonAction1, displayNone }: ModalInterface) {
  return (
    <Modals
      title={
        <>
          Guardamos los datos <br /> con éxito
        </>
      }
      description={<>Tu representante legal / persona apoderada deberá confirmar la solicitud de alta cuando termines de crearla.</>}
      buttonName1='Agregar más'
      buttonAction1={() => buttonAction1()}
      buttonName2='Entendido'
      icon='confirm'
      isOpen={isOpen}
      onClose={onClose}
      buttonAction2={onClose}
      displayNone={!displayNone}
    />
  );
}
export default SuccessAddRlFromDNB;
