/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line import/no-unresolved
import './styled/fonts';
import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/500.css';
import '@fontsource/red-hat-display/700.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved

// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender } from '@react-ib-mf/style-guide-ui';
import { AvatarChakra } from '@wow/avatar';
import { BadgeChakra } from '@wow/badge';
import { BreadcrumbChakra } from '@wow/breadcrumb';
import { ButtonChakra as Button } from '@wow/button';
import { SkeletonChakra } from '@wow/progress-indicator';
import { themeChakra } from '@wow/themes';
import { TooltipChakra } from '@wow/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { InputChakra } from '@wow/input';
import { FormChakra } from '@wow/form-control';
import { FormLabelChakra } from '@wow/form-label';
import { FormErrorChakra } from '@wow/form-error';
import { DrawerChakra } from '@wow/drawer';

import App from './App';
import { GetBankContext } from './context/GetBankContext';
import StepFormContext from './context/StepFormContext';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  ...themeChakra,

  components: {
    Button,
    Breadcrumb: BreadcrumbChakra,
    Tooltip: TooltipChakra,
    Badge: BadgeChakra,
    Skeleton: SkeletonChakra,
    Avatar: AvatarChakra,
    Input: InputChakra,
    Form: FormChakra,
    FormLabel: FormLabelChakra,
    FormError: FormErrorChakra,
    Drawer: DrawerChakra,
  },

  textStyles: {
    ...themeChakra.textStyles,
  },
  shadows: {
    ...themeChakra.shadows,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <StepFormContext.Provider>
            <GetBankContext.Provider>
              <Fonts />
              <App />
              <ToastContainer />
            </GetBankContext.Provider>
          </StepFormContext.Provider>
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
