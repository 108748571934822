import styled from 'styled-components';

const Panel = styled.div`
  .boxButton {
    display: flex;
    justify-content: space-between;
    position: fixed;
    height: 72px;
    bottom: 0px;
    background-color: white;
    margin-left: -34px;
    width: 97%;
    align-items: center;
  }
  .buttonDuo {
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    margin-right: 56px;
  }
  .boxSteps {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default Panel;
