/* eslint-disable react/jsx-no-useless-fragment */
import { Navigate } from 'react-router-dom';

import { GetBankContext } from '../../context/GetBankContext';

interface RequireAccountProps {
  pathBase: string;
  children: React.ReactNode;
}

function RequireAccount({ pathBase, children }: RequireAccountProps) {
  const state = GetBankContext.useState();
  const hasSelectedAccounts = Object.values(state.selectedAccounts).some(isSelected => isSelected);

  if (!hasSelectedAccounts) {
    return <Navigate to={pathBase} replace />;
  }

  return <>{children}</>;
}

export default RequireAccount;
