/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import image from '../../assets/img/moon.svg';

const RlEmptyPanel = styled.div`
  .centerRepLegal {
    text-align: center;
    margin-top: 45px;
  }
  .binocularesImg {
    background-image: url(${image});
    height: 76px;
    width: 76px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
  }
`;

export default RlEmptyPanel;
