import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { ChevronLeft } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

import GetBankContext from '../../context/GetBankContext';
import StepFormContext from '../../context/StepFormContext';
import Modals from '../modal';
import FlowButtonsInterface from './flowButtonsInterface';
import Panel from './styles';

function FlowButtons({ data }: FlowButtonsInterface) {
  const dispatch = GetBankContext.useDispatch();
  const dispatchStep = StepFormContext.useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openModal = () => {
    onOpen();
  };

  const handleModal = () => {
    dispatch({ type: 'resetSelectedAccounts' });
    navigate('/digitales');
  };

  return (
    <Panel>
      <Box className='boxButton' boxShadow={1}>
        <Box w='1000px'>
          <Link to={data.volver}>
            <Button
              onClick={() => {
                dispatchStep({ type: 'prevStep' });
              }}
              leftIcon={<ChevronLeft />}
              variant='primary-text'
            >
              Volver
            </Button>
          </Link>
        </Box>
        {!data?.notStep && (
          <Box className='boxSteps'>
            <Text w='90px' textStyle='labelLg'>
              Paso 2 de 3
            </Text>
            <Box className='buttonDuo'>
              <Button onClick={openModal} size='md' variant='primary-outline'>
                Cancelar
              </Button>
              <Link to={data.confirmar}>
                <Button
                  onClick={() => {
                    dispatchStep({ type: 'nextStep' });
                  }}
                  isDisabled={data.isDisabled}
                  size='md'
                  variant='primary'
                >
                  {data.text}
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
      <Modals
        title={
          <>
            ¿Querés descartar el <br />
            progreso?
          </>
        }
        description={
          <>
            Al salir de esta sección se borrará el <br />
            progreso que hiciste.
          </>
        }
        buttonName1='Cancelar'
        buttonAction2={handleModal}
        buttonAction1={onClose}
        buttonName2='Sí, descartar'
        icon='alert'
        isOpen={isOpen}
        onClose={onClose}
      />
    </Panel>
  );
}

export default FlowButtons;
