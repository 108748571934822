/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const MessagePanel = styled.div`
  .errorServiceImg {
    height: 76.1px;
    width: 76.1px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    margin-top: 50px;
  }
  .center {
    text-align: center;
  }
`;

export default MessagePanel;
