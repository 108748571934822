import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useMutation } from 'react-query';

import useCountries from '../../hooks/useCountries';
import { CreateLegalRepresentativeRequestDto } from '../../Interfaces/CreateLegalRepresentativeRequestDto';
import LegalRepresentative from '../../Interfaces/legalRepresentative';
import { createLegalRepresentative } from '../../services/api';
import DrawerGen from '../drawerGen';
import FormAddRl from '../formAddRl';
import ErrorAddRlFromRL from '../formAddRl/ErrorAddRlModal';
import RlEmptyPanel from './styles';
import SuccessAddRlFromDNB from './SuccessAddRlFromDNB';
import SuccessAddRlFromRL from './SuccessAddRlFromRL';

function RlEmpty({ application, data, fetchData }: { application: string; data?: LegalRepresentative[]; fetchData?: () => void }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ModalQuerySuccess = useDisclosure();
  const ModalQueryFailed = useDisclosure();
  const btnRef = React.useRef();
  const titleDrawer = 'Agregar representante legal o persona apoderada';
  const descriptionDrawer =
    'Completá los siguientes datos teniendo en cuenta que deben ser las mismas personas que figuran como representantes legales o apoderadas en el banco.';
  const [titleRlEmpty, descriptionRlEmpty] =
    application === 'digitales'
      ? [
          'Agregá tus representantes para continuar',
          'Deben ser las mismas personas que figuran como representantes legales o apoderadas en el banco.',
        ]
      : ['Todavía no tenés representantes legales / personas apoderadas', 'Agregá a las personas que representarán a tu empresa.'];
  const saveRl = useMutation(
    (rl: CreateLegalRepresentativeRequestDto) =>
      createLegalRepresentative({
        name: rl.name,
        lastName: rl.lastName,
        emailDirection: rl.emailDirection,
        countryCode: rl.countryCode,
        documentNumber: rl.documentNumber,
        documentType: rl.documentType,
        sendValidationEmailNow: rl.sendValidationEmailNow,
      }),

    {
      onSuccess: () => {
        ModalQuerySuccess.onOpen();
        fetchData();
      },

      onError: () => {
        ModalQueryFailed.onOpen();
      },
    },
  );

  const { data: countriesData } = useCountries();
  const canAddMoreRls = data?.length <= 3;

  const addRl = () => {
    onOpen();
  };

  return (
    <RlEmptyPanel>
      <Box className='centerRepLegal'>
        <Box className='binocularesImg ' />
        <Text mt='20px' mb='0px' as='p' textStyle='h4Bold'>
          {titleRlEmpty}
        </Text>

        <Text mt='5px' textStyle='bodyLg'>
          {descriptionRlEmpty}
        </Text>
        <Button
          id='Agregar'
          onClick={() => {
            addRl();
          }}
          variant='primary-text'
        >
          Agregar
        </Button>
        {ModalQuerySuccess.isOpen && application === 'digitales' ? (
          <SuccessAddRlFromDNB
            isOpen={ModalQuerySuccess.isOpen}
            onClose={ModalQuerySuccess.onClose}
            buttonAction1={() => addRl()}
            displayNone={canAddMoreRls}
          />
        ) : (
          <SuccessAddRlFromRL
            isOpen={ModalQuerySuccess.isOpen}
            onClose={ModalQuerySuccess.onClose}
            buttonAction1={() => addRl()}
            displayNone={canAddMoreRls}
          />
        )}
        <ErrorAddRlFromRL
          isOpen={ModalQueryFailed.isOpen}
          onClose={ModalQueryFailed.onClose}
          buttonAction1={() => addRl()}
          displayNone={canAddMoreRls}
        />

        <DrawerGen title={titleDrawer} description={descriptionDrawer} isOpen={isOpen} onClose={onClose} btnRef={btnRef}>
          <FormAddRl
            button1={{ title: 'Cancelar', action: onClose }}
            button2={{ title: 'Agregar', action: rl => saveRl.mutate(rl as CreateLegalRepresentativeRequestDto) }}
            countries={countriesData}
          />
        </DrawerGen>
      </Box>
    </RlEmptyPanel>
  );
}

export default RlEmpty;
