import styled from 'styled-components';

const FormPanel = styled.div`
  .errorLabel {
    color: red !important;
  }

  .errorLabelDni {
    color: red !important;
  }

  .css-hqf6et[aria-invalid='true'],
  .css-hqf6et[data-invalid] {
    border: 1px solid #f03c3c;
    color: #f03c3c !important;
  }
  .boxForm {
    display: flex !important;
    gap: 14px;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 19px;
  }

  .formikRl {
    display: flex;
    margin-right: -2px;
    gap: 20px;
    margin-left: -23px;
    align-items: center;
    justify-content: flex-start;
  }
  .btnsRl {
    position: absolute;
    bottom: 17px;
    gap: 20px;
    padding-right: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .rlSelect {
    font-weight: 400;
    font-size: 14px !important;
    height: 36px !important;
    border: 1px solid #74777f !important;
  }
  .labelRl {
    font-size: 14px !important;
    color: #2f3034;
  }
  .boxCuit {
    width: 48.5%;
  }
  .boxError {
    height: 52px;
  }
  .bar {
    position: fixed;
    bottom: 85px;
    left: -1px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.24);
    display: block;
    width: 100%;
  }
`;

export default FormPanel;
