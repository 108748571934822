import { useQuery } from 'react-query';

import { getPaises } from '../services/api';

export function useCountries() {
  return useQuery({
    queryKey: ['getPaises'],
    queryFn: () => getPaises().then(res => res.data.data.paisList),
  });
}

export default useCountries;
