import { Box, Button, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import disconected from '../../assets/img/disconected.svg';
import success from '../../assets/img/like.svg';
import error from '../../assets/img/under-construction.svg';
import MessagePanel from './styles';

interface PropsErrorService {
  icon?: 'success' | 'error' | 'disconected';
  title: string;
  subtitle: string;

  /**
   * @description
   * El button recibe 2 propiedades:
   * buttonLabel: se refiere al texto visible del botón recibe un string.
   * buttonAction: puede ser una cadena de string ej. redireccionamiento '/banco' o una función
   * personalizada que se ejecutará cuando se haga clic en el botón.
   */

  button?: { buttonLabel: string; buttonAction: string | ((...args: any[]) => void) };
}

function Message({ icon, title, subtitle, button }: PropsErrorService) {
  const iconImage = {
    success,
    error,
    disconected,
  };
  const iconDefault = success;
  const altText = icon && typeof icon === 'string' ? icon : 'success';
  return (
    <MessagePanel>
      <Box id='errorBox' className='center'>
        <img className='errorServiceImg' src={icon ? iconImage[icon] : iconDefault} alt={altText} />
        <Text mt='16px' mb='8px' id='title' textStyle='h4Bold'>
          {title}
        </Text>
        <Text mt='0px' id='description' textStyle='bodyLg'>
          {subtitle}
        </Text>
        {button &&
          (typeof button.buttonAction === 'string' ? (
            <Link to={button.buttonAction}>
              <Button size='md' variant='primary-text'>
                {button.buttonLabel}
              </Button>
            </Link>
          ) : (
            <Button size='md' variant='primary-text' onClick={button.buttonAction}>
              {button.buttonLabel}
            </Button>
          ))}
      </Box>
    </MessagePanel>
  );
}

export default Message;
