import styled from 'styled-components';

const Panel = styled.div`
  .container {
    margin: auto;
    margin-top: 300px;
    display: flex;
    height: 198px;
    width: 819px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
  }

  .text-container {
    text-align: center;
    margin: 0;
  }

  h1 {
    color: #2f3034;
    font-size: 24px;
    font-family: 'Red Hat Display';
    font-weight: 700;
    align-self: stretch;
    word-wrap: break-word;
    line-height: 32px;
    margin: 0;
  }
  p {
    color: #2f3034;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 400;
    align-self: stretch;
    word-wrap: break-word;
    line-height: 32px;
    margin: 0;
  }
`;
export default Panel;
