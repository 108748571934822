import ModalInterface from '../../Interfaces/modalInterface';
import Modals from '../modal';

function SuccessAddRlFromRL({ isOpen, onClose, buttonAction1, displayNone }: ModalInterface) {
  return (
    <Modals
      title={
        <>
          Agregaste tu <br />
          representante con éxito
        </>
      }
      description={<>Tu representante legal / persona apoderada deberá confirmar su email para validarlo.</>}
      buttonName1='Agregar más'
      buttonAction1={() => buttonAction1()}
      buttonName2='Entendido'
      icon='confirm'
      isOpen={isOpen}
      onClose={onClose}
      buttonAction2={onClose}
      displayNone={!displayNone}
    />
  );
}
export default SuccessAddRlFromRL;
