/**
 * Previene la entrada de caracteres no válidos en un evento de teclado para un campo de correo electrónico.
 * Solo permite caracteres alfanuméricos, guiones, guiones bajos, puntos y arrobas.
 * @remarks
 * Esta función se utiliza para manejar eventos de teclado y evitar la entrada
 * de caracteres no permitidos en un campo de correo electrónico.
 * @param {KeyboardEvent} event - El evento de teclado.
 * @returns {void}
 */
const preventNoEmailKeyPressHandler = event => {
  const pattern = /^[A-Za-z0-9\-_.@]+$/;
  if (!pattern.test(event.key)) {
    event.preventDefault();
  }
};
export default preventNoEmailKeyPressHandler;
