/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const ModalPanel = styled.div`
  /* Estilos propios de Chakra */
  .css-1ik4h6n {
    border: 0;
    color: #3f4759;
    top: 20px !important;
    right: 20px !important;
    background-color: transparent !important;
    cursor: pointer;
  }
  .css-onkibi {
    height: 16px !important;
    width: 16px !important;
  }
  .css-pv22qu {
    width: 400px !important;
    border: 1px solid #74777f !important;
    box-shadow: 0px 1px 3px 1px #00000026 !important;
    box-shadow: 0px 1px 2px 0px #0000004d !important;
    border-radius: 12px !important;
  }
  .css-1u2cvaz {
    top: 26% !important;
  }

  /* Estilos */

  .aling-center {
    text-align: center !important;
  }

  .text-style {
    text-align: center !important;
    padding: 0px 23px 16px !important;
  }

  .image-style {
    margin: auto;
    padding-top: 56px;
  }
  .image-size {
    width: 76px;
    height: 76px;
  }
  .button-container {
    display: flex !important;
    justify-content: center !important;
    margin: 24px 56px 40px !important;
    padding: 0 0 0 0 !important;
  }
  .button-white-Style {
    margin: auto !important;
    color: #2172dc !important;
    background-color: white !important;
    border: 2px solid #2172dc !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .button-blue-Style {
    margin: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .display-none {
    display: none !important;
  }
`;
export default ModalPanel;
