import { useQuery } from 'react-query';

import { getLegalRepresentative } from '../services/api';

type FetchedData = Awaited<ReturnType<typeof getLegalRepresentative>>['data']['data']['legalRepresentatives'];

export function useRepresentanteLegal<TData = FetchedData>(operador: number, { select }: { select?: (d: FetchedData) => TData } = {}) {
  return useQuery({
    queryKey: ['getRepresentanteLegal', operador],
    queryFn: () => getLegalRepresentative().then(res => res.data.data.legalRepresentatives),
    select,
  });
}

export default useRepresentanteLegal;
