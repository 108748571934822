/**
 * Previene la entrada de caracteres no válidos en un evento de teclado.
 * Solo permite caracteres alfanuméricos.
 * @remarks
 * Esta función se utiliza para manejar eventos de teclado y evitar la entrada
 * de caracteres no deseados en un campo de entrada, como un campo de DNI.
 * @param {KeyboardEvent} event - El evento de teclado.
 * @returns {void}
 */
const preventNoDniKeyPressHandler = event => {
  const pattern = /^[A-Za-z0-9]+$/;
  if (!pattern.test(event.key)) {
    event.preventDefault();
  }
};
export default preventNoDniKeyPressHandler;
