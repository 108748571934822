// eslint-disable-next-line import/no-unresolved
import { getOidcStoraged } from '@react-ib-mf/style-guide-ui';
import { Outlet, useParams } from 'react-router-dom';

import Breadcrumb from '../components/breadcrumb';
import FlowButtons from '../components/flowButtons';
import Header from '../components/header';
import LoadingCircular from '../components/loading/circular';
import RlEmpty from '../components/rlEmpty';
import RlFull from '../components/rlFull';
import { useRepresentanteLegal } from '../hooks/useRepresentanteLegal';
import ErrorServiceLayout from './ErrorServiceLayout';
import Panel from './styled';

function Project() {
  const urlParams = useParams();
  const back = '/digitales/bancos';
  const cont = '/digitales/resumen-suscripcion';
  const {
    profile: { operador },
  } = getOidcStoraged();

  const { data: rlsData, isLoading: rlsIsLoading, refetch: rlsRefetch } = useRepresentanteLegal(operador);

  if (rlsIsLoading) {
    return <LoadingCircular />;
  }

  if (!rlsData) {
    return (
      <>
        <Breadcrumb />
        <Panel>
          <Header application={urlParams.mf} />
          <ErrorServiceLayout rlsRefetch={() => rlsRefetch()} />
          <Outlet />
        </Panel>
      </>
    );
  }

  return (
    <>
      {rlsIsLoading && <LoadingCircular />}
      <Breadcrumb />
      <Panel>
        <Header data={rlsData} application={urlParams.mf} fetchData={rlsRefetch} />
        {rlsData.length > 0 ? (
          <RlFull data={rlsData} operador={operador} fetchData={rlsRefetch} microfrontend={urlParams.mf} />
        ) : (
          <RlEmpty data={rlsData} application={urlParams.mf} fetchData={rlsRefetch} />
        )}
        <FlowButtons
          data={{
            isDisabled: rlsData.length === 0,
            volver: back,
            confirmar: cont,
            text: 'Continuar',
          }}
        />
        <Outlet />
      </Panel>
    </>
  );
}
export default Project;
