import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Text } from '@chakra-ui/react';

import DrawerGenInterface from '../../Interfaces/drawerGenInterface';
import DrawerPanel from './styles';

function DrawerGen({ title, description, isOpen, onClose, btnRef, children }: DrawerGenInterface) {
  return (
    <Drawer size='md' isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />

      <DrawerPanel>
        <DrawerContent pl='20px'>
          <DrawerCloseButton />

          <Text id='title' mb='0px' mt='40px' as='h1' textStyle='h4Bold'>
            {title}
          </Text>

          {description ? (
            <Text mb='10px' mt='10px' id='description' textStyle='bodyMd'>
              {description}
            </Text>
          ) : null}
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </DrawerPanel>
    </Drawer>
  );
}
export default DrawerGen;
