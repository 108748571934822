import React from 'react';

import FlowButtons from '../components/flowButtons';
import Message from '../components/message';

interface Refetched {
  rlsRefetch: (...args: any[]) => void;
}

function ErrorServiceLayout({ rlsRefetch }: Refetched) {
  return (
    <>
      <Message
        icon='error'
        title='La información no está disponible'
        subtitle='Estamos trabajando para solucionarlo, volvé a intentar más tarde.'
        button={{
          buttonLabel: 'Reintentar',
          buttonAction: () => {
            rlsRefetch();
          },
        }}
      />
      <FlowButtons
        data={{
          volver: '/digitales/bancos',
          isDisabled: true,
          text: 'Elegir',
          notStep: true,
        }}
      />
    </>
  );
}

export default ErrorServiceLayout;
