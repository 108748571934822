import styled from 'styled-components';

import image from '../../assets/img/alertblue.svg';

const HeaderPanel = styled.div`
  .helpIconRl {
    background-image: url(${image});
    height: 22px;
    width: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 15px;
  }
  .rlFlexiStart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .rlTitleTooltip {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export default HeaderPanel;
