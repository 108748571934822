import { Account } from '../Interfaces/getBank';
import { createdPersistedReducerContext } from '../utils/createPersistedReducerContext';

/**
 * The original implementation of this component is in the DNB project in this component. {@link src\context\GetBankContext.ts}
 * If a change is made to that DNB component, please keep in mind to make it in this component as well.
 */

interface State {
  owner: string | null;
  selectedAccounts: { [key: string]: boolean };
}
type Action<TType, TPayload> = { type: TType; payload?: TPayload };
type Actions =
  | Action<'setOwner', State['owner']> //
  | Action<'toggleSelectedAccount', Account['cbu']>
  | Action<'setMultipleSelectedAccounts', { [key: Account['cbu']]: boolean }>
  | Action<'resetSelectedAccounts', undefined>;

function reducer(state: State, action: Actions): State {
  const { type, payload } = action;

  switch (type) {
    case 'setOwner':
      return {
        ...state,
        owner: payload,
        selectedAccounts: {},
      };

    case 'toggleSelectedAccount':
      return {
        ...state,
        selectedAccounts: {
          ...state.selectedAccounts,
          [payload]: !state.selectedAccounts[payload],
        },
      };

    case 'setMultipleSelectedAccounts':
      return {
        ...state,
        selectedAccounts: {
          ...state.selectedAccounts,
          ...payload,
        },
      };
    case 'resetSelectedAccounts':
      return {
        ...state,
        selectedAccounts: {},
      };

    default:
      return state;
  }
}

const initState: State = {
  owner: null,
  selectedAccounts: {},
};

const persistedKey = `${new URL(window.location.href).hostname.split('.').reverse().join('.')}:get-bank-context`;

export const GetBankContext = createdPersistedReducerContext(reducer, initState, persistedKey, { storage: sessionStorage });
export default GetBankContext;
