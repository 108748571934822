const BANCOS_TIPO_SUSCRIPCION = {
  FISICA: 'física',
  DIGITAL: 'digital',
};
export const CONST_BREADCRUMB = [
  {
    key: 'inicio',
    id: 'Inicio',
    label: 'Inicio',
    link: '/',
  },
  {
    key: 'administracion',
    id: 'Administracion',
    label: 'Administración',
    link: '',
  },
  {
    key: 'abm',
    id: 'abm',
    label: 'ABM configuración de datos',
    link: '/menu/Administración/abm',
  },
];

export default BANCOS_TIPO_SUSCRIPCION;
