import styled from 'styled-components';

const FormPanel = styled.div`
  margin-left: -13px;
  .boxForm {
    display: flex !important;
    gap: 14px;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 19px;
  }

  .formikRl {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
  }
  .btnsRl {
    position: absolute;
    bottom: 17px;
    gap: 20px;
    padding-right: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .labelRl {
    font-size: 14px !important;
    color: #2f3034 !important;
  }
  .boxError {
    height: 52px;
  }
  .descriptionBox {
    display: flex;
    outline: 1px solid #ffddb4;
    border-radius: 12px;
    width: 457px;
  }
  .alertIcon {
    color: white;
  }
  .boxIcon {
    display: flex;
    align-items: center;
    border-radius: 12px 0 0 12px;
    background-color: #a76a00;
  }
  .rlDataBox {
    margin-top: 28px;
    margin-bottom: 18px;
    display: flex;
    outline: 1px solid #bec6dc;
    border-radius: 12px;
  }
  .avatarBox {
    display: flex;
    align-items: center;
  }
  .boxFlex {
    display: flex;
  }
  .boxValidar {
    background-color: #ffeedb;
    border-radius: 0 12px 12px 0;
  }
  .uppercase {
    text-transform: capitalize;
  }
  .bar {
    position: fixed;
    bottom: 85px;
    left: -1px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.24);
    display: block;
    width: 100%;
  }
`;

export default FormPanel;
