/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import edit from '../../assets/img/edit.svg';
import editDis from '../../assets/img/edit-dis.svg';
import send from '../../assets/img/send.svg';
import sendDis from '../../assets/img/send-dis.svg';
import trash from '../../assets/img/trash.svg';
import trashDis from '../../assets/img/trash-dis.svg';

const RlFullPanel = styled.div`
  .trash-rl {
    background-image: url(${trash});
    height: 24px;
    width: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 15px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .send-rl {
    background-image: url(${send});
    height: 24px;
    width: 24px;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 15px;
    cursor: pointer;
    display: none;
  }
  .edit-rl {
    background-image: url(${edit});
    height: 24px;
    width: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .trash-rl-dis {
    background-image: url(${trashDis});
    height: 24px;
    width: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 15px;
    border: 0;
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
  }

  .send-rl-dis {
    background-image: url(${sendDis});
    height: 24px;
    width: 24px;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 15px;
    cursor: not-allowed;
    pointer-events: none;
  }
  .edit-rl-dis {
    background-image: url(${editDis});
    height: 24px;
    width: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
  }
  .css-176ejqa {
    border: 0;
    color: white;
    background-color: transparent !important;

    cursor: pointer;
  }
  button .css-onkibi {
    height: 13px !important;
    width: 13px !important;
  }
  .toast-toast-id {
    height: 50px;
  }

  .my-tab-Rl {
    display: flex;
    align-content: space-between;
    justify-content: space-around;
    flex-direction: column;
    font-size: 14px;
  }
  .header-table-rl,
  .colum-table-rl {
    min-width: 20%;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
  }
  .colum-table-rl-icons {
    min-width: 11%;
    width: 100%;
    padding-right: 20px;
    display: flex;
    align-items: end;
    justify-content: right;
  }
  .table-Rl {
    display: flex;
    flex-direction: row;
    height: 40px;
    background-color: #eff0fa;
    font-weight: bold;
    align-items: center;
    padding: 5px;
  }
  .table-in-Rl {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #c7c6ca;
  }
  .widthMin {
    min-width: 170px;
  }
  .uppercase {
    text-transform: capitalize;
  }
`;

export default RlFullPanel;
