const formatCuil = (input: string): string => {
  const value = input.replace(/[^\d]/g, '');

  let formattedCuil = value;

  if (value.length > 2 && value.length <= 10) {
    formattedCuil = `${value.slice(0, 2)}-${value.slice(2)}`;
  } else if (value.length > 10) {
    formattedCuil = `${value.slice(0, 2)}-${value.slice(2, 10)}-${value.slice(10, 11)}`;
  }

  return formattedCuil;
};
export default formatCuil;
